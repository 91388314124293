
.side-bar-button{
    width: 317px;
    display: flex;
    background:none;
    color: white;
    flex-direction: column;
    align-items: center;
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
}

.side-bar-button-container{
    display: flex;
    width: 100%;
    background : none;
    align-items: center;
    height: 70px;
    padding-left: 55px;
}

.side-bar-button-container-show{
    display: flex;
    width: 100%;
    background-color: #FF4C98;
    align-items: center;
    height: 70px;
    padding-left: 55px;
    color: black;
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
} 
.side-bar-button-container-show-collapsed{
    display: flex;
    width: 100%;
    align-items: center;
    height: 70px;
    padding-left: 100px;
    background-color: #FF4C98;
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
}

.side-bar-button-container-collapsed{
    display: flex;
    width: 100%;
    align-items: center;
    height: 70px;
    padding-left: 100px;
}



.side-bar-button-show{
    width: 317px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color : #222222;
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
}

.side-bar-button-text{
    margin-left: 20px;
    margin-right: 20px;
    font-family: ReithSans-Regular;
    font-size: 24px;
}


.side-bar-button-icon{
    height: 32px;
    width: auto;
    object-fit: contain;
    transition: fill 300ms ease-in-out;
}

.side-bar-button-icon-show path{
    fill: black !important;
    transition: fill 300ms ease-in-out;
}

.sub-list{
    margin-top: 20px;
    align-self: start;
    margin-left: 15px;
}
   
.sub-list > ul{
   list-style: none;
   text-align: left;
}

.sub-list > ul > p{
    margin-top: 20px;
    font-family: ReithSans-Regular;
    font-size: 24px;
 }

.collapsed {
    margin: 100px;
    height: 0;
    overflow: hidden;
  }

  .side-menu-collapsed{
      height: 70px;
      overflow: hidden;
      margin-bottom: 20px;
  }

  .side-menu-expanded{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}