.subtitle-settings-container{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: black;
    margin-top: 5px;
}
.subtitle-settings-left-bar{
    width: 2px;
    background-color: #3c3c3c ;
}

.subtitle-settings-selector{
    margin-top: 450px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subtitle-label{
    color: white;
    width: 225px;
    margin-left: 20px;
    margin-top: 0;
    height: 22px;
    text-align: left;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 24px;
    font-family: 'ReithSans-Medium';


}
.subtitle-button-icon{
    width: 32px;
    height: 30px ;
    margin-left: 10px;
}

.subtitle-checkbox{
    width: 32px;
    height: 32px;
}

.subtitle-item-list{

}