/* body {
  background: hsla(210, 20%, 90%, 1);
  padding: 2em 0;
  line-height: 1.6;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  font-family: "Open Sans", sans-serif;
} */

/* h1,
h2,
h3,
h5 {
  margin: 0;
} */

/* .header {
  text-align: center;
  margin-bottom: 6px;
} */
/* 
.header h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 3em;
  margin-bottom: 0.2em;
  line-height: 1.2;
  color: #222;
} */

/* .header h3 {
  font-weight: 400;
  color: #555;
  width: 30em;
} */



.card {
  background: black;
  border: 2px solid black;

  /* width: 24em; */
  height: 100%;
  /* border-radius: 0.6em; */
  /* margin: 1em; */
  overflow: hidden;
  cursor: pointer;
  padding: 2px;
  /* box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03); */
  /* transition: all ease 200ms; */
}

/* .card:hover, */
.card-onSelected {
  border: 3px solid #FFFFFF;
  transition: border 300ms ease-in-out;
  /* padding: 0px; */
  /* box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02); */
}

.card img {
  /* width: 100%;
    height: 150px;
    object-fit: cover; */
  width: 302px;
  height: 168px;
  flex-grow: 0;
  object-fit: fill;
}

.card .card-title {
  /* color: #222;
    margin-top: -0.2em;
    line-height: 1.4;
    font-size: 1.3em;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    transition: all ease-in 100ms; */
  width: 296px;
  /* width: 100%; */
  /* height: 24px; */
  flex-grow: 0;
  object-fit: contain;
  font-family: ReithSans-Bold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ccc;
}
.card-title-selected { 
  transition: color 300ms ease-in-out;
  color: #fff !important;
}
/* .card p {
    color: #777;
  } */

.card .card-season {
  width: 295px;
  height: auto;
  flex-grow: 0;
  object-fit: contain;
  font-family: ReithSans-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.card .card-availability {
  /* color: #bbb;
    font-weight: 700;
    font-size: 0.7em;
    letter-spacing: 0.04em;
    margin: 1.4em 0 0 0;
    text-transform: uppercase; */
  margin-bottom: 5px;
  width: 296px;
  height: 22px;
  flex-grow: 0;
  object-fit: contain;
  font-family: ReithSans-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #747478;
}

.card-body {
  padding: 0.2em;
}

.card p {
  margin: 0;
}

.w3-light-grey
{
  color:#000 !important;
  background-color:#E9E9E9 !important;
  margin-top: -5px;
  
}

.hide{
  display: none;
}

.w3-blue,.w3-hover-blue:hover{color:#fff!important;background-color:#CD2874!important;height:8px;}
