.autoplay-container{
    width: 1000px;
    height: 350px;
    display: flex;
    flex-direction: column;
    margin-left: 25px;

}

.autoplay-title{
    text-align: left;
    font-size: 24px;

}

.autoplay-control-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}