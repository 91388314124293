.side-menu-settings{
    text-align: left;
    margin-left: 15px;
}

.SettingsMenuItem{
    width: 330px;
    height: 64px;
    font-family: ReithSans-Regular;
    font-size: 24px;
    line-height: 64px;
    padding-left: 60px;
}

.selectedMenuItem{
    background-color: rgb(25, 25, 26);
}

.focusedMenuItem{
    box-shadow:inset 0px 0px 0px 2px #FF4C98;
}
