.aboutTitle{
    width: 549px;
    height: 48px;
    font-family: ReithSans-Bold;
    font-size: 44px;
    text-align: left;   
    margin-top: 88px;
}

.extremeOuterDiv{
    display: flex;
    flex-direction: row;
    margin-top: 64px;
}

.aboutLine{
    width: 1254px;
    height: 1px;
    border-bottom: 0.5px solid white;
    position: absolute;
    margin-top: 30px;
}

.outerDiv {
    width: 1174px;
    height: 921px;
    overflow: auto;
    direction: rtl;
    margin-left: 10px;
}
.aboutContent {
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow: auto;
    direction: ltr;
    margin-left: 50px;
}

.mainTitle {
    font-family: ReithSans-Light;
    font-size: 44px;
    width: 1075px;
    height: 48px;
    line-height: 48px;
    margin-top: 0px;
}

.smallSecondaryTitle {
    font-family: ReithSans-Regular;
    font-size: 24px;
    margin-top: -20px;
}

.firstParaTitle{
    font-family: ReithSans-Light;
    font-size: 44px;
    margin-top: 20px;
}

.firstParaContent {
    font-family: ReithSans-Regular;
    font-size: 24px;
    width: 936px;
    height: 100px;
    margin-top: -20px;
}

.secondParaTitle{
    font-family: ReithSans-Light;
    font-size: 44px;
    margin-top: 60px;
}

.secondParaContent{
    font-family: ReithSans-Regular;
    font-size: 24px;
    width: 936px;
    margin-top: -20px;
}

.thirdParaContent{
    font-family: ReithSans-Regular;
    font-size: 24px;
    width: 936px;
    margin-top: -20px;
}

.fourthParaContent{
    font-family: ReithSans-Regular;
    font-size: 24px;
    width: 936px;
    margin-top: -20px;
}

.thirdParaTitle{
    font-family: ReithSans-Light;
    font-size: 44px;
    margin-top: 775px;
}

.fourthParaTitle{
    font-family: ReithSans-Light;
    font-size: 44px;
    margin-top: -260px;
}

.upAndDownKeys {
    width: 63px;
    height: 63px;
    background-color: #666666;
    display:flex;
    align-items:center;
    justify-content:center;
    box-sizing: border-box;
}

.upAndDownImage{
    width: 32px;
    height: 26px;

}

.keyContainer{
    display: flex;
    flex-direction: column;
}

.focused{
    border-style: solid;
    border-width: 4px;
    border-color:#FF4C98;
}