.settings-button-selected{
    width: 32px;
    height: 32px;
    display: flex;
}

.settings-button-selected svg path {
    fill:  #FF4C98;
}
.settings-button-unselected{
    width: 32px;
    height: 32px;
    display: flex;
}