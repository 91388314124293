.spotlight-details-container{
    width: 400px;
    text-align: left;
    margin-left: 150px;
}

.home-bbc-logo{
    width: 100%;
}

.home-bbc-logo-container{
    width: 213px;
    height: 36px;
    margin-left: 150px;
    margin-top: 25px;
}

.spotlight-container{
    width: 1866px;
    height: 621px;
    background-image: url('../../assets/testImages/summer_of_rockets.jpeg');
}

.spotlight-container-filter{
    filter:brightness(50%)
}

.spotlight-title{
    width: 617px;
    height: 48px;
    font-family: ReithSans;
    font-size: 44px;
}
.spotlight-subtitle{
    width: 617px;
    height: 21px;
    font-family: ReithSans;
    font-size: 16px;

}
.spotlight-description{
    width: 617px;
    height: 66px;
    font-family: ReithSans;
    font-size: 24px;

}


.spotlight-button-container{
    width: 400px;
    height: 90px;
    display: flex;
    gap: 15px;
    flex-direction: row;
}