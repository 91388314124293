.control-bar-fg{
    height: 8px;
    width: 1700px;
    background-color: #FF4C98;
    position: absolute;
}

.control-bar-bg{
    height: 8px;
    width: 1700px;
    background-color:#e9e9e9;
    position: absolute;
}

.control-bar-container {
    width: 1700px;
    height: 8px;
    top: 863px;
    left: 67px;
}
.control-bar-head{
    position: absolute;
    height: 24px;
    bottom: -8px;
    left: 1694px;
}

.control-bar-time-container{
    display: flex;
    color: white;
    font-size: 24px;
}

.control-bar-remain-time{
    margin-left: 1519px;
}
