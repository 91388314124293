.lineOne{
    width: 715px;
    height: 48px;
    font-family: ReithSans-Light;
    font-size: 44px;
    text-align: left;   
    margin-top: 60px;
    position: relative;
    color: white;
}

.lineTwo{
    width: 931px;
    height: 36px;
    font-family: ReithSans-Light;
    font-size: 32px;
    text-align: left;   
    margin-top: 20px;
    position: relative;
    color: white;
}

.ratingPanel{
    margin-top: 20px;
    display: grid;
    width: 614px;
    height: 150px;
    padding: 10px;
    gap: 15px;
    grid-template-areas:
    ". . . . . . . . ."
    "y y y y y y y y y";
}

.ratingButton {
    width: 140px;
    height: 60px;
    background-color: #747478;
    text-align: center;
    line-height: 60px;
    font-family: ReithSans-Regular;
    font-size: 32px;
    transition: box-shadow 300ms ease-in-out;
}

.nextButton {
    width: 460px;
    height: 60px;
    background-color: black;
    text-align: center;
    line-height: 60px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    color: #747478;
    grid-area: y;
    box-shadow:inset 0px 0px 0px 2px #747478;
}

.selected {
    background-color: white;
}

.moveToNext{
    width: 460px;
    height: 60px;
    padding: 7px;
    background: #FF4C98 content-box;
    text-align: center;
    line-height: 60px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    grid-area: y;
    color: black;
}

.ratingDivSelect{
    grid-area: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140.8px;
    height: 60px;
    color: black;
    font-family: ReithSans-Regular;
    font-size: 32px;
}

.ratingDivSelect img{
    width: 48px;
    height: 48px;
}
.moveToNext.ratingFocused {
    transition: box-shadow 300ms ease-in-out;
    box-shadow:inset 0px 0px 0px 4px #2261C5;
    padding: 0;
}
.ratingFocused{
    box-shadow:inset 0px 0px 0px 4px #FF4C98;
}