.backContainer{
    width: 95px;
    height: 28px;
    padding-left: 55px;
}

.imageDiv{
    display: flex;
    width: 95px;
    height: 28px;
    justify-content: center;
    padding: 10px;
}

.showBorder {
    box-shadow:inset 0px 0px 0px 2px #FF4C98;
}

.settings-back-image{
    width: 100%;
}
