.heroslider-details-container{
    width: 718px;
    text-align: left;
    display: flex;
    flex-direction: column;
}




.heroslider-container{
    width: 1866px;
    height: 450px;
    display: flex;
}

.heroslider-container-filter{
    filter:brightness(50%)
}

.heroslider-title{
    width: 617px;
    height: 48px;
    font-family: ReithSans;
    font-size: 44px;
}
.heroslider-subtitle{
    width: 617px;
    height: 21px;
    font-family: ReithSans;
    font-size: 16px;

}
.heroslider-description{
    width: 617px;
    height: 66px;
    font-family: ReithSans;
    font-size: 24px;

}


.heroslider-button-container{
    width: 500px;
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.heroslider-image-container{
    width: 727px;
    margin-left: 0px;
}

.heroslider-image{
    width: 100%;
}