.pinTextContainer {
    margin-top: 20px;
    display: flex;
    position: relative;
    flex-direction: row;
}

.pinTextContainer > div {
    width: 143px;
    height: 90px;
    background-color: #e9e9e9;
    text-align: center;
    color: black;
    font-family: ReithSans-Bold;
    font-size: 32px;
    margin-right: 10px;
    line-height: 90px;
    transition: box-shadow 300ms ease-in-out;
}