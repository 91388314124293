.player-controls-button-unselected {
    width: 100px;
    height: 200px;

}

.player-controls-button-selected {
    width: 100px;
    height: 200px;
    background-color: black;

}

