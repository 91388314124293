.boundary-circle {
    transition: transform 0.25s ease-in-out, 
                animation 0.25s ease-in-out, 
                background 0.25s ease-in-out;
    border-radius: 50%;
    background: radial-gradient(circle, transparent 20%, #eee 80%);
    animation: zoomInZoomOut 0.25s ease-in-out;
}

  @keyframes zoomInZoomOut {
    0% {
      background: radial-gradient(circle, transparent 20%, #eee 80%);
      transform: scale(1);
    }
    50% {
      background: radial-gradient(circle, transparent 20%, #eee 80%);
      transform: scale(1.5);
    }
    100% {
      background: radial-gradient(circle, #eee 20%, transparent 80% );
      transform: scale(1);
    }
  }
.button-zoom-out {
    animation: zoomOut 0.25s ease-in-out;
}

@keyframes zoomOut {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0.75);
    }
}

.play-button-selected{
    width: 112px;
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
}

.play-button-unselected{
    width: 112px;
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;

}

.play-button-icon{

    width: 25px;
}

.play-button-outline{
    width: 100px;
    display: block;
    position: absolute;
}