.typedAnswer {
    width: 447px;
    height: 60px;
    font-family: ReithSans-Regular;
    font-size: 24px;
    text-align: left;   
    margin-top: 20px;
    position: relative;
    color: black;
    background-color: white;
    line-height: 40px;
    padding-left: 15px;
    display: flex;
    align-items: center;
}