.splash-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1440px;
    width: 1920px;
    justify-content: center;
    align-items: center;
    background: url(../../assets/background/blue-background.jpg);
    background-position: center;
    background-size: cover;
}

.logo {
    width: 479.6px;
    height: 72.9px;
    position: relative;
    margin-top: 400px;
}

.message {
    height: 48px;
    font-family: ReithSans-Light;
    font-size: 44px;
    letter-spacing: normal;
    color: white;
    position: relative;
    text-align: center;
  }

.splashButtonPanel {
    margin-left: 665px;
    margin-top: 30px;
    width: 400px;
    position: absolute;
    display: grid;
    padding: 10px;
    gap: .5em;
    grid-row-gap: 30px;
    column-gap: 30px;
    grid-template-areas:
    "a b"
    "c c";
}

.splashButtonDiv {
    text-align: center;
    width: 260px;
    line-height: 60px;
}

.later.focused{
    border: 5px solid #2261C5 !important;
    transition: border 300ms ease-in-out, outline 300ms ease-in-out;
}

.splashButtonDiv.focused{
    border-style: solid;
    border-width: 5px;
    border-color: #2261C5;
    transition: border 300ms ease-in-out;
}

.splashButtonDiv.labelFocus{
    border:5px solid #2261C5 !important;
    transition: border 300ms ease-in-out;
    /*
     setting below width and height to compensate for outline size
    */
    width: 396px !important;
    line-height: 56px;
}

.splashButtonDiv.signIn {
    grid-area: a;
    text-align: center;
    font-family: ReithSans-Bold;
    font-size: 24px;
    font-weight: bold;
    font-style: bold;
    color: black;
    background: #FF4C98;
    transition: border 300ms ease-in-out;
}

.splashButtonDiv.later {
    grid-area: b;
    text-align: center;
    font-family: ReithSans-Bold;
    font-size: 24px;
    font-weight: bold;
    font-style: bold;
    color: white;
    border: 1px solid white;
    transition: border 300ms ease-in-out;
}

.splashButtonDiv.hyperLink {
    margin-left: 60px;
    width: 400px;
    grid-area: c;
    text-align: center;
    font-family: ReithSans-Bold;
    font-size: 24px;
    font-weight: bold;
    font-style: bold;
    color: white;
    text-decoration: underline;
}

.astro-more-info-container{
    width: 1920px;
    height: 1080px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    justify-content: center;
    z-index: 100;
}
