.textBox {
  width: 56%;
  background-color: #111111;
  color: #e9e9e9;
  font-size: 2em;
  font-weight: normal;
  height: 100px;
  line-height: 100px;
  text-align: left;
  padding-left: 1em;
  display: flex;
  align-items: center;
}

.textBox.focused {
  border-color: #FF4C98;
  border-style: solid;
  border-width: 0.2em;
}

.textBox .caret {
  width: 1px;
  height: 1em;
  background-color: gray;
  animation-name: blink;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  opacity: 1;
}

.textBox input:focus + .caret {
  display: none;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.textBox p {
  color: gray;
}
