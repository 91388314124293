.securityQuestionsContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.questionsPanel{
    margin-top: 24px;
    display: grid;
    width: 723px;
    height: 312px;
    padding: 0px;
    gap: 24px;
    grid-template-areas:
    "x"
    "y"
    "z"
    "a"
    "b";
}
.firstLine{
    width: 1075px;
    height: 48px;
    font-family: ReithSans-Light;
    font-size: 44px;
    text-align: left;   
    margin-top: 60px;
    position: relative;
    color: white;
}

.questionsButtonOne {
    width: 680px;
    height: 48px;
    background-color: #232326;
    text-align: left;
    font-family: ReithSans-Regular;
    font-size: 32px;
    color: #e9e9e9;
    grid-area: x;
    padding: 10px 20px 10px 20px;   
    transition: box-shadow 300ms ease-in-out;
}
.questionsButtonTwo {
    width: 670px;
    height: 48px;
    background-color: #232326;
    text-align: left;
    font-family: ReithSans-Regular;
    font-size: 32px;
    color: #e9e9e9;
    grid-area: y;
    padding: 10px 20px 10px 20px;
    transition: box-shadow 300ms ease-in-out;
}
.questionsButtonThree {
    width: 670px;
    height: 48px;
    background-color: #232326;
    text-align: left;
    font-family: ReithSans-Regular;
    font-size: 32px;
    color: #e9e9e9;
    grid-area: z;
    padding: 10px 20px 10px 20px;
    transition: box-shadow 300ms ease-in-out;
}
.questionsButtonFour {
    width: 670px;
    height: 48px;
    background-color: #232326;
    text-align: left;
    font-family: ReithSans-Regular;
    font-size: 32px;
    color: #e9e9e9;
    grid-area: a;
    padding: 10px 20px 10px 20px;
    transition: box-shadow 300ms ease-in-out;
}

.nextButtonFive {
    width: 460px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    line-height: 40px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    color: #747478;
    margin-top: 20px;
    text-align: center;
    box-shadow:inset 0px 0px 0px 2px #747478;
    grid-area: b;
    transition: box-shadow 300ms ease-in-out;
}

.questionSelected {
    box-shadow:inset 0px 0px 0px 2px black;
    height: 60px;
    padding: 7px;
    color: black;
    background: #FF4C98 content-box;
}

.focusedQuestion {
    box-shadow:inset 0px 0px 0px 4px #FF4C98;
}

.questionSelected.focusedQuestion {
    box-shadow:inset 0px 0px 0px 4px #2261C5;
    padding: 0;
}

.focusedSelected {
    background-color:rgb(29, 26, 26);;
}