.App {
  text-align: center;
  display: flex;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@media screen and (max-device-width: 1280px) {
  .App{
    text-align: center;
    display: flex;
    width: 100%;
    height: 100%;
    margin-left: 32px;
  }
}

/* Add the decaration on top */
@font-face { 
  font-family: 'ReithSans';
  src: url('./assets/fonts/BBCReithSans_W_Lt.woff') format('woff');
  }

@font-face { 
    font-family: 'ReithSans-Bold';
    src: url('./assets/fonts/BBCReithSans_W_Bd.woff') format('woff');
}

@font-face { 
  font-family: 'ReithSans-Light';
  src: url('./assets/fonts/BBCReithSans_W_Lt.woff') format('woff');
}

@font-face { 
  font-family: 'ReithSans-Medium';
  src: url('./assets/fonts/BBCReithSans_W_Md.woff') format('woff');
}

@font-face { 
  font-family: 'ReithSans-Regular';
  src: url('./assets/fonts/BBCReithSans_W_Rg.woff') format('woff');
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}






video::-webkit-media-text-track-container {
  -webkit-transform: translate(0px, -30px) !important;
  transform: translate(0px, -30px) !important;
  position: relative;

} 

