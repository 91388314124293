.vlistitem.focused{
    background-color:rebeccapurple;
    border-color: burlywood;
}

.vlistitem{
    border-width: .1em;
    border-style: solid;
    border-color: white;
    background-color:grey;
}