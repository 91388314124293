.autoplaycard {
    background: black;
    border: 2px solid black;
  
    /* width: 24em; */
    height: 100%;
    /* border-radius: 0.6em; */
    /* margin: 1em; */
    overflow: hidden;
    cursor: pointer;
    padding: 2px;
    display: flex;
    /* box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
      0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03); */
    /* transition: all ease 200ms; */
  }
  
  /* .autoplaycard:hover, */
  .onSelected {
    border: 4px solid #FF4C98;
    transform: scale(1.003);
    padding: 0px;
    /* box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
      0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
      0 -6px 32px -6px hsla(0, 0%, 0%, 0.02); */
  }
  
  .autoplaycard img {
    /* width: 100%;
      height: 150px;
      object-fit: cover; */
    width: 296px;
    height: 168px;
    flex-grow: 0;
    object-fit: fill;
  }
  
  .autoplaycard .autoplaycard-title {
    /* color: #222;
      margin-top: -0.2em;
      line-height: 1.4;
      font-size: 1.3em;
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
      transition: all ease-in 100ms; */
    width: 296px;
    /* width: 100%; */
    /* height: 24px; */
    flex-grow: 0;
    object-fit: contain;
    font-family: ReithSans-Bold;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ccc;
    margin-bottom: 10px;
  }
  
  /* .autoplaycard p {
      color: #777;
    } */
  
  .autoplaycard .autoplaycard-episode {
    width: 510px;
    height: auto;
    flex-grow: 0;
    object-fit: contain;
    font-family: ReithSans-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  
  .autoplaycard .autoplaycard-description {
    width: 520px;
    height: auto;
    flex-grow: 0;
    object-fit: contain;
    font-family: ReithSans-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #fff;;
  }
  
  .autoplaycard-body {
    padding: 0.2em;
    margin-left: 20px;
  }
  
  .autoplaycard p {
    margin: 0;
  }