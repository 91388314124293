.player-pin-keyboard-panel {
    margin-top: 20px;
    display: grid;
    width: 460px;
    height: 143px;
    color: white;
    padding: 10px;
    gap: 16px;
    grid-template-areas:
    ". . . . . ."
    ". . . . u u"
    "x x x x x x"
    "y y y y y y";
}

.player-pin-kb-container{
    width: 90%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.playerPinKeyBoardButton {
    box-sizing: border-box;
    font-family: ReithSans-Regular;
    font-size: 28px;
    width: 63px;
    height: 63px;
    background-color: #232326;
    text-align: center;
    line-height: 63px;
    transition: box-shadow 300ms ease-in-out;
}

.playerPinKeyBoardButton.deleteKeyForPin{
    display: flex;
    justify-content: center;
    width: 143px;
    height: 63px;
    grid-area: u;
}

.deleteImage{
    width: 83px;
    height: 33px;
    margin: auto;
}

.playerPinKeyBoardButton.focusedElement{
    box-shadow:inset 0px 0px 0px 4px #FF4C98;
}

.selectedKey {
    background: #FF4C98 content-box;
    color:black;
}

.playerPinKeyBoardButton.focusedElement .selectedKey {
    box-shadow:inset 0px 0px 0px 4px #2261C5;
}