.side-menu-container{
    width: 319px;
    height: 1080px;
    background-color: #222222;
    position: fixed;
    display: flex;
}

.side-menu{
    padding-top : 195px;
    width: calc(100% - 2px);
}


.side-menu>ul{
    list-style: none;
    margin: 0;
    padding-left: 0;
    overflow: hidden;
}


.collapsed .sub-list{
    width: 70px;
    overflow: hidden;
  }

  .side-menu-container.side-menu-collapsed{
    width: 100px;
    background-color: #222222;
  }

  .expanded{
    height: 173px;
    display: flex;
    flex-direction: column;
}

.side-menu-bar{
  height: 1080px;
  width: 2px;
  background-color: #3c3c3c;
}