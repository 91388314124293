.modal-button-ok{
    margin-right: 20px;
}

.modalTitle{
    width: 512px;
    height: 72px;
    font-family: ReithSans-Light;
    font-size: 32px;
    margin-bottom: 40px;
    vertical-align: middle;
}

.modalImg{
    width: 50px;
    height: 50px;
    vertical-align: middle;
}

.modal-buttons-list{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modal-two-button-container{
    display: flex;
    color: black !important;
    flex-direction: column;
    align-items: center;
    
}

.modal-title{
    width: 512px;
    height: 72px;
    font-family: ReithSans-Light;
    font-size: 32px;
    margin-bottom: 80px;
    text-align: center;
}

.astro-button-modal.astro-button-selected{
    margin-top: 30px;
    background: #FF4C98 content-box;
    color: black;
    font-size: 28px;
    width: 233px;
    height: 44px;
    font-weight: bold;
    font-size: 28px;
    border: 4px solid #2261C5;
    margin-top: 0;
    margin-left: 20px;
    margin-right: 0px;
}

.astro-button-modal-fullscreen.astro-button-selected{
    margin-top: 30px;
    background: #FF4C98 content-box;
    color: black;
    font-size: 28px;
    width : 245px;
    height: 51px;
    font-weight: bold;
    font-size: 28px;
    border: 4px solid #2261C5;
    margin-top: 0;
}

.astro-button-modal.astro-button-unselected{
    background-color: #FF4C98;
    color: black;
    font-size: 28px;
    width: 245px;
    padding: 0px;
    box-sizing: border-box;
    height: 52px;
    margin-left: 20px;
    font-weight: bold;
    margin-right: 0px;
    font-size: 28px;
}
.astro-button-modal-fullscreen.astro-button-unselected{
    background-color: #FF4C98;
    margin-top: 30px;
    color: black;;
    font-size: 28px;
    width : 245px;
    height: 48px;
    font-weight: bold;
    padding : 3px;
    font-size: 28px;
    margin-top: 0;
}

.astro-button-modal.uncolored.astro-button-selected{
    margin-top: 30px;
    background: none;
    color: white;
    font-size: 28px;
    width: 233px;
    height: 44px;
    font-weight: bold;
    font-size: 28px;
    border: 4px solid #2261C5;
    margin-top: 0;
    margin-left: 20px;
    margin-right: 0px;
}

.astro-button-modal-fullscreen.uncolored.astro-button-selected{
    margin-top: 30px;
    background: none;
    color: white;
    font-size: 28px;
    width : 245px;
    height: 51px;
    font-weight: bold;
    font-size: 28px;
    border: 4px solid #2261C5;
    margin-top: 0;
}

.astro-button-modal.uncolored.astro-button-unselected{
    background-color: inherit;
    border: 3px solid white;
    color: white;
    font-size: 28px;
    width: 245px;
    padding: 0px;
    box-sizing: border-box;
    height: 52px;
    margin-left: 20px;
    font-weight: bold;
    margin-right: 0px;
    font-size: 28px;
}

.astro-button-modal-fullscreen.uncolored.astro-button-unselected{
    background: inherit;
    margin-top: 30px;
    color: white;
    font-size: 28px;
    width : 245px;
    height: 48px;
    font-weight: bold;
    padding : 3px;
    font-size: 28px;
    border: 3px solid white;
    margin-top: 0;
}

.astro-button-modal.whitebg-uncolored.astro-button-selected{
    margin-top: 30px;
    background: none;
    color: black;
    font-size: 28px;
    width: 233px;
    height: 44px;
    font-weight: bold;
    font-size: 28px;
    border: 4px solid #2261C5;
    margin-top: 0;
    margin-left: 20px;
    margin-right: 0px;
}

.astro-button-modal-fullscreen.whitebg-uncolored.astro-button-selected{
    margin-top: 30px;
    background: none;
    color: black;
    font-size: 28px;
    width : 245px;
    height: 51px;
    font-weight: bold;
    font-size: 28px;
    border: 4px solid #2261C5;
    margin-top: 0;
}

.astro-button-modal.whitebg-uncolored.astro-button-unselected{
    background-color: inherit;
    border: 3px solid black;
    color: black;
    font-size: 28px;
    width: 245px;
    padding: 0px;
    box-sizing: border-box;
    height: 52px;
    margin-left: 20px;
    font-weight: bold;
    margin-right: 0px;
    font-size: 28px;
}

.astro-button-modal-fullscreen.whitebg-uncolored.astro-button-unselected{
    background: inherit;
    margin-top: 30px;
    color: black;
    font-size: 28px;
    width : 245px;
    height: 48px;
    font-weight: bold;
    padding : 3px;
    font-size: 28px;
    border: 3px solid black;
    margin-top: 0;
}

.astro-button-modal {
    width : 150px;
    justify-content: center;
    height: 60px;
    justify-items: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    margin-right: 15px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    transition: border 300ms ease-in-out;
}

.astro-button-modal-fullscreen {
    width : 150px;
    justify-content: center;
    height: 60px;
    justify-items: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    margin-right: 15px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    transition: border 300ms ease-in-out;
}




.modalTitleDiv{
    margin-bottom: 30px;
}

.modal-buttons-list-fullscreen{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}   

.modal-two-button-container-fullscreen{
    margin-top: 10px;
    display: flex;
    color: white;
    flex-direction: column;
    align-items: left;
    width: 750px;
    height: 250px;
    
}
.modal-subtitle-container{
    display: flex;
    flex-direction: column;
}

.modal-title-fullscreen{
    font-size: 54px;
    font-family: ReithSans-Bold;

}

.modal-sub-title-one{
    font-size: 54px;
    font-family: ReithSans-Bold;
    margin-top: 10px;
}

.modal-sub-title-two{
    font-size: 24px;
    width: 1300px;
    font-family: ReithSans-Medium;
    margin-top: 10px;
    margin-bottom: 10px;

}


