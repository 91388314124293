
.astro-button.astro-button-unselected{
    background: none;
    border: 1px solid white;
    color: white;
    font-size: 28px;
    width : 225px;
    height: 60px;
}

.astro-button-play.astro-button-unselected{
    background-color:#FF4C98;
    color: black;
    font-size: 28px;
    width : 225px;
    height: 60px;
    font-weight: bold;
    font-size: 28px;
    border: 1px solid transparent;
}

.astro-button.astro-button-selected {
    background: none;
    color: white;
    font-size: 28px;
    width : 225px;
    height: 60px;
    border: 3px solid #2261C5;;
    transition: border 300ms ease-in-out;
}

.astro-button-play.astro-button-selected {
    background-color:#FF4C98;
    color: black;
    font-size: 28px;
    width : 225px;
    height: 60px;
    font-weight: bold;
    font-size: 28px;
    border: 3px solid #2261C5;
    transition:  border 300ms ease-in-out;
}


.astro-button {
    width : 150px;
    justify-content: center;
    height: 60px;
    justify-items: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    margin-right: 25px;
    font-family: ReithSans-Bold;
    font-size: 24px;
}

.astro-button-play {
    width : 225px;
    justify-content: center;
    height: 60px;
    justify-items: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    padding-right: 15px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    margin-right: 25px;
}

.astro-button-icon{
    width: 32px;
    height: 32px;
}

.astro-button-svg path{
    fill: black;
}