.season-number{
    font-size: 32px;
    margin : 15px
}

.season-number-container{
    display: inline-block;
    width: 69px;
    height: 69px;
    margin-left: 25px;
}

.season-number-container-focused{
    display: inline-block;
    width: 69px;
    height: 69px;
    margin-left: 25px;
    background-image: -webkit-linear-gradient(top, transparent 62px,#FF4C98 40px, #FF4C98 100%);
}

.selectedSeason{
    background-color: #222222;
    color: #FF4C98;
    transition: color 300ms ease-in-out;
}