.skip-button-selected{
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skip-button-unselected{
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skip-button-boundary-circle {
    transition: transform 0.25s ease-in-out, 
                animation 0.25s ease-in-out, 
                background 0.25s ease-in-out;
    border-radius: 50%;
    background: radial-gradient(circle, transparent 20%, #eee 80%);
    animation: zoomInZoomOut 0.25s ease-in-out;
    padding: 5px 5px 10px 5px;
  }

  @keyframes zoomInZoomOut {
    0% {
      background: radial-gradient(circle, transparent 20%, #eee 80%);
      transform: scale(1);
    }
    50% {
      background: radial-gradient(circle, transparent 20%, #eee 80%);
      transform: scale(1.5);
    }
    100% {
      background: radial-gradient(circle, #eee 20%, transparent 80% );
      transform: scale(1);
    }
  }
.skip-button-zoom-out {
    animation: zoomOut 0.25s ease-in-out;
}

@keyframes zoomOut {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0.75);
    }
}