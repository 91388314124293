.search-screen {
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  padding: 0 4em;
}

.search-screen .textBox {
  margin: 2em 0 1em 0;
  white-space: pre;
}

.search-screen .SearchForHeader p {
  /* width: 326px; */
  /* height: 48px; */
  margin-bottom: 1em;
  margin-top: 0;
  object-fit: contain;
  font-family: ReithSans-Medium;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.search-screen .SearchForHeader .text-style-1 {
  font-weight: bold;
}

.search-screen .noResultDiv {
  width: 987px;
  height: 110px;
  padding: 0;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  flex-grow: 0;
  object-fit: contain;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  /* margin: auto 0px; */
}

.search-screen .noResultDiv .p1,
.search-screen .noResultDiv .p2 {
  /* width: 217.7px; */
  margin: 5px 1em;
  /* height: 64px; */
  /* height: 50px */
  flex-grow: 0;
  object-fit: contain;
  font-family: ReithSans-Bold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #080808;
}

.search-screen .noResultDiv .p2 {
  font-weight: normal;
  font-family: ReithSans-Regular;
}

.search-screen .search-results-div {
  margin-bottom: 1em;
}

.removeSearchMargin{
  margin-left: 103px;
  z-index: 99;
}

.addSearchMargin{
  margin-left: 320px;
  z-index: 99;
}