.home-screen{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
}

.home-slider-list-container{
    margin-left: 150px;
}

.removeHomeMargin{
    margin-left: 103px;
    z-index: 99;
}

.addHomeMargin{
    margin-left: 320px;
    z-index : 99
}