.pinTextContainer {
    margin-top: 20px;
    display: flex;
    position: relative;
    flex-direction: row;
    column-gap: 20px;
}

.pinTextContainer > div {
    width: 143px;
    height: 90px;
    background-color: #e9e9e9;
    text-align: center;
    color: black;
    font-family: ReithSans-Bold;
    font-size: 32px;
    line-height: 90px;
    transition: box-shadow 300ms ease-in-out;
}

.cursorFocused {
    box-shadow:inset 0px 0px 0px 5px #FF4C98;
}

.keyboardPanel {
    margin-top: 20px;
    display: grid;
    width: 460px;
    height: 143px;
    padding: 10px;
    gap: 16px;
    grid-template-areas:
    ". . . . . ."
    ". . . . u u"
    "x x x x x x"
    "y y y y y y";
}

.pinKeyBoardButton {
    box-sizing: border-box;
    font-family: ReithSans-Regular;
    font-size: 28px;
    width: auto;
    min-width: 63px;
    height: 63px;
    background-color: #232326;
    text-align: center;
    line-height: 63px;
    transition: box-shadow 300ms ease-in-out;
}

.pinKeyBoardButton.deleteKeyForPin{
    display: flex;
    justify-content: center;
    width: 143px;
    height: 63px;
    grid-area: u;
}

.deleteImage{
    width: 83px;
    height: 33px;
    margin: auto;
}

.nextKey.focusedElement {
    box-shadow:inset 0px 0px 0px 4px #2261C5 !important;
    padding: 0;
    transition: box-shadow 300ms ease-in-out;
}

.pinKeyBoardButton.focusedElement{
    box-shadow:inset 0px 0px 0px 4px #FF4C98;
}

.errorDiv{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 636px;
    height: 106px;
    background-color: #e4134f;
    margin-top: 20px;
    align-content: center;
}

.errorDivHide {
    display: none;
}

.errorLineOne{
    width: 391px;
    height: 30px;
    text-align: left;
    color: white;
    font-family: ReithSans-Bold;
    font-size: 24px;
    line-height: 30px;
    margin-left: 20px;
    margin-top: 20px;
}

.errorLineTwo{
    width: 391px;
    height: 30px;
    text-align: left;
    color: white;
    font-family: ReithSans-Regular;
    font-size: 24px;
    line-height: 30px;
    margin-left: 20px;
}

.nextKey {
    margin-top: 20px;
    width: 460px;
    height: 60px;
    display: flex;
    justify-content: center;
    padding: 7px;
    background-color: #000000;
    align-items: center;
    line-height: 63px;
    color:#747478;
    grid-area: x;
    font-family: ReithSans-Bold;
    font-size: 24px;
    box-shadow:inset 0px 0px 0px 2px #747478;
}

.forgottenKey {
    margin-top: 10px;
    width: 460px;
    height: 60px;
    background-color: black;
    text-align: center;
    line-height: 63px;
    color: white;
    grid-area: y;
    font-family: ReithSans-Bold;
    font-size: 24px;
    box-shadow:inset 0px 0px 0px 2px white;
}

.selectedKey {
    box-shadow:inset 0px 0px 0px 2px black;
    padding: 7px;
    background: #FF4C98 content-box;
    color:black !important;
}