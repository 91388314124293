.createPinContainer{
    display: flex;
    flex-direction: column;
    position: relative;
}

.startLabel{
    width:1258px;
    height: 48px;
    font-family: ReithSans-Light;
    font-size: 35px;
    text-align: left;   
    margin-top: 60px;
    position: relative;
}

.setPin {
    margin-top: 30px;
    position: relative;
    color: white;
    text-align: center;
    width: 460px;
    height: 60px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    box-shadow:inset 0px 0px 0px 2px white;
    line-height: 60px;
}

.focusedItem {
    box-shadow:inset 0px 0px 0px 2px #FF4C98;
}