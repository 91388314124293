.channels-screen{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
}
.channels-bbc-logo-container{
    width: 213px;
    height: 36px;
}
.channels-info-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 304px;
}
.channels-text-container{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.channels-title{
    width: 550px;
    height: 60px;
    font-size: 56px;
}
.channels-description{
    width: 618px;
    font-size: 24px;

}
.channels-spec-logo-container{
    width: 175px;
    height: 132px;
    margin-left: calc(51% + (100% - 1766px));
}


.channels-spec-logo{
    position: absolute;
    width: 175px;
    height: 132px;
    right: 100px;
    top: 217px;
}

.channels-bbc-logo{
    width: 100%;
}

.channels-title-items-container {
    margin-left: 109px;
    margin-top: 37px;
}

.channels-carousal-list{
    margin-left: 90px;
}

.removeChannelsMargin{
    margin-left: 103px;
    z-index: 99;
}
  
.addChannelsMargin{
    margin-left: 320px;
    z-index: 99;
}

.channels-horizontal-line{
    width: 1633px;
    height: 2px;
    align-self: start;
    background-color: #3c3c3c;
    margin-left: 106px;
}