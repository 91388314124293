.changePinQuestionsContainer {
    display: flex;
    flex-direction: column;
}
.changePinSecurityPanel{
    margin-top: 60px;
    display: grid;
    width: 460px;
    height: 340px;
    padding: 10px;
    gap: 24px;
    grid-template-areas:
    "a a"
    "b b"
    "c c"
    "x x"
    "y z";
}
.questionsButton {
    width: 460px;
    height: 60px;
    background-color:black;
    text-align: center;
    line-height: 60px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    color: white;
    padding: 5px;
    grid-area: a;
    box-shadow:inset 0px 0px 0px 2px white;
}
.changePinButton {
    width: 460px;
    height: 60px;
    background-color:black;
    text-align: center;
    line-height: 60px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    color: white;
    padding: 5px;
    grid-area: b;
    box-shadow:inset 0px 0px 0px 2px white;
}
.forgottenPinButton {
    width: 460px;
    height: 60px;
    background-color:black;
    text-align: center;
    line-height: 60px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    color: white;
    padding: 5px;
    grid-area: c;
    box-shadow:inset 0px 0px 0px 2px white;
}
.ratingLabel {
    width: 460px;
    height: 60px;
    background-color:black;
    text-align: left;
    line-height: 60px;
    font-family: ReithSans-Light;
    font-size: 28px;
    color: white;
    padding: 5px;
    grid-area: x;
}
.selectedRating {
    width: 218px;
    height: 60px;
    background-color:white;
    text-align: center;
    line-height: 60px;
    font-family: ReithSans-Regular;
    font-size: 32px;
    color: black;
    padding: 5px;
    grid-area: y;
}
.changeButton {
    width: 218px;
    height: 60px;
    background-color:black;
    text-align: center;
    line-height: 60px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    color: white;
    grid-area: z;
    box-shadow:inset 0px 0px 0px 3px white;
}
.focusedButton{
    box-shadow:inset 0px 0px 0px 3px #FF4C98;
}
.ratingDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 218px;
    height: 60px;
    background-color:white;
    color: black;
    font-family: ReithSans-Regular;
    font-size: 32px;
    grid-area: y;
}
.ratingDiv img{
    width: 50px;
    height: 50px;
}