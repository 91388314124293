.headerTitle{
    width: 549px;
    height: 48px;
    font-family: ReithSans-Bold;
    font-size: 44px;
    text-align: left;   
    margin-top: 88px;
}

.line{
    width: 1254px;
    height: 1px;
    border-bottom: 0.5px solid white;
    position: absolute;
    margin-top: 30px;
}