.choosePinContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.lineOne{
    width:1258px;
    height: 48px;
    font-family: ReithSans-Light;
    font-size: 35px;
    text-align: left;   
    margin-top: 60px;
    position: relative;
    color: white;
}

.parentalLabel{
    width: 702px;
    height: 56px;
    font-family: ReithSans-Regular;
    font-size: 24px;
    text-align: left;   
    margin-top: 20px;
    position: relative;
    color: white;
}

.lineOneforgotten{
    width: 779px;
    height: 36px;
    font-family: ReithSans-Regular;
    font-size: 32px;
    text-align: left;   
    position: relative;
    color: white;
    margin-top: 50px;
}

.hideLabel {
    display:none;
}
