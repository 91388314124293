.header{
    width: 549px;
    height: 48px;
    font-family: ReithSans-Bold;
    font-size: 44px;
    text-align: left;   
    margin-top: 88px;
}

.SignOutline{
    width: 1254px;
    height: 1px;
    border-bottom: 0.5px solid white;
    margin-top: 30px;
}

.SignOutlineOne {
    margin-top: 50px;
    width: 1003px;
    height: 144px;
    font-family: ReithSans-Light;
    font-size: 44px;
    text-align: left;
    line-height: 54px;
}

.SignOutlineTwo {
    margin-top: 40px;
    width: 601px;
    height: 28px;
    font-family: ReithSans-Regular;
    font-size: 24px;
    text-align: left;
    line-height: 28px;
}

.SignOutbutton {
    color: black;
    width: 464px;
    height: 60px;
    background: #FF4C98 content-box;
    padding: 8px;
    font-family: ReithSans-Bold;
    font-size: 24px;
    line-height: 60px;
    margin-top: 30px;
    transition: box-shadow 300ms ease-in-out;;
}

.signoutFocused {
    box-shadow:inset 0px 0px 0px 4px #2261C5;
    padding: 0;
}