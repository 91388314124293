
.player-controls{
    display: flex;
    flex-direction: row;
    width: 1500px;
    align-items: center;
    justify-content: center;
    height: 112px;
    top : 1000px;
    left : 780px;
    margin-top: 40px;
}

video::-webkit-media-text-track-container {
    -webkit-transform: translate(0px, -200px) !important;
    transform: translate(0px, -200px) !important;
    position: relative;
  
  } 
  

.player-controls-container {
    display: flex;
    flex-direction: column;
    width: 1920px;
    align-items: center;
    height: 289px;
    padding-top: 80px;
    background-color: black;
}

.player-controls-parent-container{
    display: flex;
    flex-direction: row;
}

.settings-container{
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: 30px;
}

.audio-container{
    align-items: center;
    justify-content: center;
    align-self: center;
}