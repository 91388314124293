.autoplay-timer-container{
    margin-right: 25px;
}

.autoplay-timer-circle{
    width: 72px;
    height: 72px;
}

.autoplay-timer-counter{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}

.autoplay-timer-image{
    width: 72px;
    height: 72px;
    position: relative;
}

.autoplay-cancel-text.autoplay-cancel-onselected{
    color: #FF4C98 ;
}

