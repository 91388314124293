.settings-screen {
    width: 1920px;
    height: 1080px;
    background-color:black;
    color: white;
    display: flex;
}

.SidemenuContainer {
    width: 397px;
    height: 600px;
    margin-top: 80px;
    text-align: left;
}

.SettingsLabel{
    width: 192px;
    height: 48px;
    font-family: ReithSans-Light;
    font-size: 44px;
    line-height: 48px;
    padding-left: 20px;
    margin-bottom: 40px;
}

.ComponentContainer{
    padding-left: 60px;
}

.SideMenuDiv {
    margin-top: 85px;
}

.settingsLabelDiv{
    display: flex;
    width: 244px;
    height: 48px;
    padding-left: 55px;
    margin-bottom: 20px;
}

.settings-ph-icon{
    width: 48px;
    height: 46px;
}



.app-version-text-container{
    position: absolute;
    left: 1600px;
    top: 900px;
}

.app-version-text{
    font-size: 24px;
}