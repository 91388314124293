

.eventListItemContainer{
    display: flex;
    flex-direction: row;
    color: #e9e9e9;
    /* background-color:#111111; */
}

/* Thumbnail */
.eventSeparator{
    height: 20px;
}

.eventPlayButton{
    width: 3em;
    height: 3em;
    padding: 1px;
}

.eventThumbailFocusContainer.tileFocused{
    box-shadow:inset 0px 0px 0px 5px #FF4C98;
    transition: box-shadow 300ms ease-in-out;
}
.eventThumbnailImage{
    padding: 0em;
    height: 180px;
    width: 360px;
    display: grid;
    align-items: center;
    justify-content: center;
    background-size: cover;
}

.eventThumbailFocusContainer{
    padding: 5px;
}

.circle{
    stroke:#ffffff;
    fill: rgba(0, 0, 0, 0.3);
    stroke-width: .1em;
}

.triangle{
    stroke: white;
    fill: #ffffff
}

/* Event Detail */

.eventDetailContainer{
    display: flex;
    flex-direction: column;
    padding-left: 1em;
}

.eventDetailHeading{
    margin-bottom: .3em;
    font-size: 28px;
    font-weight: bold;
    text-align: left;
}

.eventDetailMetadata{
    margin-bottom: .3em;
    display: flex;
    flex-direction: row;
    color: #747478;
    font-size: 1em;
    font-weight: normal;
    /* Use the following three lines to vertically and horizontally center text in dev */
    height: 23px;
    line-height: 23px;
    text-align: center;
}

.eventDetailSynopis{
    text-align: left;
    font-size: 24px;
    width: 776px;
    height: 34px;
    font-weight: normal;
}

.eventDetailDuration{
    margin-right: .5em;

}
.eventDetailUnit{
    margin-right: .5em;
}
.eventDetailPG{
    margin-right: 1em;
    font-size: 0.75em;
    font-weight: normal;
    /* Use the following three lines to vertically and horizontally center text in dev */
    line-height: 20px;
    height: 20px;
    text-align: center;
    /* Border */
    border-width:1px;
    border-style: solid;
    border-color: #747478;
    width: auto;
    min-width: 20px;
}
.eventDetailVideoFormat{
    height: 20px;
    background-color: #747478;
    color:#111111 ;
    margin-right: .2em;
    font-size: 0.75em;
    font-weight: bold;
    /* Use the following three lines to vertically and horizontally center text in dev */
    width: 20px;
    line-height: 20px;
    text-align: center;
    /* Border */
    border-width:1px;
    border-style: solid;
    border-color: #747478;
}
