.subtitle-item-container{
    color:white;
    width: 250px;
    height: 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.subtitle-radio-button{
    visibility: hidden;
}


.subtitle-radio-button:checked + span:after {
    opacity: 1;
}

.subtitle-radio-button:checked + span {
    border: 5px solid #FF4C98;
}

.custom-radio::after{
    content: '';
    width: 13px;
    height: 13px;
    background: #FF4C98;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
}

.custom-radio {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border: 5px solid white;
    border-radius: 50%;
    display: inline-block;
    position: relative;
}

.subtitle-item-text{
    font-size:  24px;
    line-height: 24px;
    width: auto;
    height: auto;
    margin: 0;
    margin-left: 10px;
    font-family: 'ReithSans-Medium';
}

.subtitle-focused{
    background-color: gray;
}