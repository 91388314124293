.loading-screen-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1920px;
    height: 1080px;
    background: url("../../assets/background/blue-background.jpg");
    position: absolute;
    z-index: 100;
}

.loading-screen-logo {
    width: 484px;
    margin-bottom: 32px;
}

.loading-screen-text {
    color: var(--White, #FFF);
    text-align: center;
    font-family: BBC Reith Sans;
    font-size: 44px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 32px;
}
.loagin-screen-icon{
    transform: translate(-50%, -50%);
    margin: 0;
    width: 112px;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}


@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}