.categories-screen{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
}
.categories-bbc-logo-container{
    width: 213px;
    height: 36px;
}
.categories-info-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.categories-text-container{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.categories-title{
    width: 550px;
    height: 60px;
    font-size: 56px;
}
.categories-description{
    width: 618px;
    height: 56px;
    font-size: 24px;

}
.categories-spec-logo-container{
    width: 175px;
    height: 132px;
    margin-left: 800px;
}


.categories-spec-logo{
    width: 100%;
}

.categories-bbc-logo{
    width: 100%;
}

.categories-title-items-container {
    margin-left: 100px;
    margin-top: 37px;
}

.categories-carousal-list{
    margin-left: 90px;
}

.removeCategoriesMargin{
    margin-left: 103px;
    z-index: 99;
}
  
.addCategoriesMargin{
    margin-left: 320px;
    z-index: 99;
}

.categories-horizontal-line{
    width: 1633px;
    height: 2px;
    align-self: start;
    background-color: #3c3c3c;
    margin-left: 106px;
}