.player-container{
    height: 100%;
    width: 1920px;
    position: absolute;
    left: 0px;
}

.brightcove-react-player-loader{
    width: 100%;
    height: 100%;
}

.video-js{
    width: 100%;
    height: 100%;
}

.vjs-control-bar{
    display: none !important;
}
.vjs-dock-text{
    display: none;
}



.player-overlay{
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 1920px;
    align-items: center;
    height: 1080px;
    left: 0px;
}

.vjs-mouse.bc-player-default_default .vjs-control-bar{
    display: none;
}

.player-controls > button {
   height: 40px;
}

.player-control-abs-container{
    position: absolute;
    top: 781px;
}

.player-tile-details-container{
    position: absolute;
    height: 189px;
    top: -8px;
    width: 1920px;
    background-color: black;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: white;
}

.details-title{
    width: 893px;
    height: 60px;
    font-size: 56px;
    margin-top: 48px;
    margin-left: 67px;
    margin-bottom: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.details-subtitle{
    width: 893px;
    margin-top: 0;
    height: 22px;
    font-size: 24px;
    margin-left: 67px;

}

.subtitle-settings-abs-container{
    width: 400px;
    height: 1080px;
    z-index : 99;
    left: 1520px;
    position: absolute;
}

.player-pin-entry-abs-container{
    position: absolute;
    width: 1920px;
    height: 1080px;
}

.autoplay-abs-container{
    width: 1920px;
    height: 289px;
    position: absolute;
    top : 792px;
    background-color: black;
    color: white;
}

