.custom-button-selected{
    border: 3px solid #2261C5;
    border-radius: 1px;
    color: black;
    background-color: #FF4C98; 
    width:100%;    
    height: 70px;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    overflow: hidden;
    position: relative;
    transition: border 300ms ease-in-out;
}

.custom-button-unselected{
    border: 1px solid white;
    border-radius: 1px;
    color: white;
    background-color:black; 
    width:100%;    
    height: 70px;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    overflow: hidden;
    position: relative;
    transition: border 300ms ease-in-out;
}

.custom-button-container{
    width: 100%;
}