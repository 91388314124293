.securityAnswersContainer {
    display: flex;
    flex-direction: column;
    position: relative;
}

.lineOne{
    width: 1075px;
    height: 48px;
    font-family: ReithSans-Light;
    font-size: 44px;
    text-align: left;   
    margin-top: 60px;
    position: relative;
    color: white;
}

.question{
    width: 1300px;
    height: 36px;
    font-family: ReithSans-Regular;
    font-size: 32px;
    text-align: left;   
    margin-top: 24px;
    position: relative;
    color: white;
}

.checkAnsLineOne {
    width: 965px;
    height: 36px;
    font-family: ReithSans-Regular;
    font-size: 32px;
    margin-top: 40px;
    position: relative;
    color: white;
    text-align: left; 
}

.checkAnsLineTwo {
    width: 719px;
    height: 36px;
    font-family: ReithSans-Bold;
    font-size: 32px;
    margin-top: 24px;
    position: relative;
    color: white;
    text-align: left; 
}