.carousel-container {
  /* background: #cf5151; */
  /* display: flex ;
    flex-direction: row !important;
    flex-wrap: nowrap;
    overflow: hidden; */
  width: 1745px;
  height: 278px;
  object-fit: contain;
}

.carousel-item {
  /* height:20rem; */
  min-width: 100%;
  width: 100%;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.carousal-outer-container.slider-focused{
  filter: brightness(100%);
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  height: 325px;
}

.carousal-outer-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  height: 325px;
  filter: brightness(50%);
  /* overflow: hidden; */
}

.carousal-container {
  display: flex;
}

.carousalListFilter {
  filter: brightness(50%);
}

.carousalList .title {
  text-align: left;
  padding: 10px;
  background-color: #757575;
}

/* @media screen and (max-width: 650px) */

.carousel {
  text-align: left;
  /* margin: 16px; */
  padding: 22px 16px;
  /* background-color: #111; */
  font-family: BBCReithSans, sans-serif;
  color: #ccc;
  height: 425px;
  position: relative;
  max-width: 100%;
}

.carousel__header {
  font-size: 18px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 20px;

  display: inline-block;
  font-family: BBCReithSans, sans-serif;
  color: #ccc;
}

.cards {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 10px;
  height: 302px;

  /* width: 1745px;
    height: 278px;
    object-fit: contain; */
}
.hero-carousel-bbc-logo{
  width : 215px;
  height: 29px;
}
