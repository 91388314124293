.player-pin-entry-container{
    width: 1920px;
    height: 1080px;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.player-pin-entry-display-items{
    width: 65%;
    display: flex;
    flex-direction: column;
    height: 600px;
    justify-content: center;
    align-items: flex-start;
    margin-left: 200px;
}

.player-pin-entry-control-items{
    width: 35%;
    background-color: black;
    height: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.player-pin-entry-title{
    font-size: 72px;
    margin: 0;
    margin-bottom: 20px;
}

.player-pin-entry-sub-title{
    font-size: 28px;
    margin: 0;
}

.player-pin-entry-play-container{
    width: 77%;
}

.errorDivPlayer{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 700px;
    height: 106px;
    background-color: #e4134f;
    margin-top: 20px;
    align-content: center;
}

.errorDivHide {
    display: none;
}

.errorLineOnePlayer{
    height: 30px;
    text-align: left;
    color: white;
    font-family: ReithSans-Bold;
    font-size: 24px;
    line-height: 30px;
    margin-left: 20px;
    margin-top: 20px;
}

.errorLineTwoPlayer{
    height: 30px;
    text-align: left;
    color: white;
    font-family: ReithSans-Regular;
    font-size: 24px;
    line-height: 30px;
    margin-left: 20px;
}