.keyboardContainer{
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
}
.answerKeyBoardPanel{
    margin-top: 30px;
    display: grid;
    width: 699px;
    height: 300px;
    padding: 10px;
    gap: 16px;
    grid-template-areas:
    ". . . . . . . . ."
    ". . . . . . . . ."
    ". . . . . . . . ."
    "u u u v v v w w w"
    "x x x x x x x x x"
    "y y y y y y z z z";
}

.keyBoardButton {
    box-sizing: border-box;
    width: 63px;
    height: 63px;
    background-color: #232326;
    text-align: center;
    line-height: 63px;
    font-family: ReithSans-Regular;
    font-size: 28px;
    transition: box-shadow 300ms ease-in-out;
}

.keyBoardButton.keyFocused{
    box-shadow:inset 0px 0px 0px 4px #FF4C98 !important;
}

.keyBoardButton.nextKeyFocused{
    /* box-shadow:inset 0px 0px 0px 4px #FF4C98; */
    /* box-shadow: inset 0px 0px 0px 2px rgb(241, 2, 2); */
    border-style: solid;
    border-radius: 2px;
    border-color: #FF4C98;
}

.keyBoardButton.buttonOne{
    margin-top: 24px;
    width: 476px;
    height: 76px;
    display: flex;
    color: #747478;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height:  60px;
    grid-area: x;
    font-family: ReithSans-Bold;
    font-size: 24px;
    background-color: black;
    box-shadow: inset 0px 0px 0px 2px #747478;
}

.keyBoardButton.bgChange.nextKeyFocused{
    box-shadow: inset 0px 0px 0px 4px #2261C5;
    border: none;
    padding: 0;
}

.keyBoardButton.bgChange{
    /* background-color: #FF4C98; */
    box-shadow: inset 0px 0px 0px 2px black;
    color: black;
    /* border: solid 3px #FF4C98; */
    background: #FF4C98 content-box;
    padding: 6px;
}

.keyBoardButton.buttonTwo{
    margin-top: 10px;
    width: 460px;
    height: 60px;
    background-color: black;
    text-align: center;
    line-height:  60px;
    grid-area: y;
    font-family: ReithSans-Bold;
    font-size: 24px;
    box-shadow: inset 0px 0px 0px 2px white;
}

.keyBoardButton.infolabel{
    padding: 12px;
    width: 223px;
    height: 44px;
    text-align: left;
    line-height:  44px;
    grid-area: z;
    font-family: ReithSans-Regular;
    font-size: 18px;
    background: black;
    display: none;
}

.keyBoardButton.showInfolabel{
    display: block;
}

.keyBoardButton.special{
    width: 222px;
    height: 63px;
    background-color: #232326;
    text-align: center;
    grid-area: u;
}

.keyBoardButton.space{
    width: 222px;
    height: 63px;
    background-color: #232326;
    text-align: center;
    grid-area: v;
}

.keyBoardButton.delete{
    display: flex;
    justify-content: center;
    width: 222px;
    height: 63px;
    background-color: #232326;
    text-align: center;
    grid-area: w;
}

.keyBoardButtonContainer{
    width: 63px;
    height: 63px;
    background-color: #232326;
    text-align: center;
    line-height: 63px;
}

.keyBoardButtonContainer.special{
    width: 222px;
    height: 63px;
    grid-area: u;
}

.keyBoardButtonContainer.space{
    width: 222px;
    height: 63px;
    grid-area: v;
}

.keyBoardButtonContainer.delete{
    width: 222px;
    height: 63px;
    grid-area: w;
}

.deleteImageForSecAnswer{
    width: 83px;
    height: 33px;
    margin: auto;
}

.spaceImageDiv{
    width: 82px;
    height: 19px;
    margin: auto;
}

.errorDiv{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 462px;
    height: 106px;
    background-color: #e4134f;
    margin-top: 20px;
    align-content: center;
}

.errorDivHide {
    display: none;
}

.errorLineOne{
    width: 391px;
    height: 30px;
    text-align: left;
    color: white;
    font-family: ReithSans-Bold;
    font-size: 24px;
    line-height: 30px;
    margin-left: 20px;
    margin-top: 10px;
}

.errorLineTwo{
    width: 391px;
    height: 30px;
    text-align: left;
    color: white;
    font-family: ReithSans-Regular;
    font-size: 24px;
    line-height: 30px;
    margin-left: 20px;
}
.errorDiv::after{
    content: " ";
    position: absolute;
    left: 45%;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #e4134f;
}