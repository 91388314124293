.tandCTitle{
    width: 749px;
    height: 48px;
    font-family: ReithSans-Bold;
    font-size: 44px;
    text-align: left;   
    margin-top: 88px;
}

.tandCLine{
    width: 1254px;
    height: 1px;
    border-bottom: 0.5px solid white;
    position: absolute;
    margin-top: 30px;
}

.extremeOuterDivForTandC{
    display: flex;
    flex-direction: row;
    margin-top: 64px;
}

.outerTandCDiv {
    width: 1174px;
    height: 921px;
    overflow: auto;
    direction: rtl;
    margin-left: 10px;
}

.tandCContent {
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow: auto;
    direction: ltr;
    margin-left: 50px;
}

.subHeading{
    font-family: ReithSans-Bold;
    font-size: 32px;
    margin-top: -20px;
    width: 900px;
}

.subHeadingTitle {
    font-family: ReithSans-Bold;
    font-size: 32px;
}

.normalContent{
    font-family: ReithSans-Regular;
    font-size: 24px;
    width: 936px;
    margin-top: -20px;
}