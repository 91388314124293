.season-selector-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 90px;
    margin-top: -14px;
    margin-bottom: -14px;
    color: rgb(116, 116, 120);
}

.season-text{
    width: 126px;
    height: 36px;
    font-size: 32px;
}

.season-list{
    list-style: none;
    display: inline;
    
}

.divLineTop {
    width: 1841px;
    height: 0.2px;
    border-bottom: 0.2px solid rgb(34, 34, 34);
}

.divLineBottom {
    width: 1841px;
    height: 0.5px;
    border-bottom: 0.5px solid rgb(34, 34, 34);
}

.outerSeasonContainer{
    display: flex;
    flex-direction: column;
}

