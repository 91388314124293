.helpTitle{
    width: 549px;
    height: 48px;
    font-family: ReithSans-Bold;
    font-size: 44px;
    text-align: left;   
    margin-top: 88px;
}

.helpLine{
    width: 1254px;
    height: 1px;
    border-bottom: 0.5px solid white;
    position: absolute;
    margin-top: 30px;
}

.extremeOuterHelpDiv{
    display: flex;
    flex-direction: row;
    margin-top: 64px;
}

.helpOuterDiv {
    width: 1174px;
    height: 921px;
    overflow: auto;
    direction: rtl;
    margin-left: 10px;
}

.helpContent {
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow: auto;
    direction: ltr;
    margin-left: 50px;
}

.boldTitle{
    font-family: ReithSans-Bold;
    font-size: 32px;
    width: 900px;
    height: 36px;
    line-height: 36px;
    margin-top: 0px;
}
.firstParaContentHelp {
    font-family: ReithSans-Regular;
    font-size: 24px;
    width: 1000px;
    margin-top: -20px;
}
