.keyBoardButtonContainer{
    width: 4em;
    height: 4em;
    background-color: #111111;
    text-align: center;
    line-height: 4em;
}

.keyBoardButtonContainer.special{
    width: 13em;
    height: 4em;
    grid-area: u;
}

.keyBoardButtonContainer.space{
    width: 13em;
    height: 4em;
    grid-area: v;
}

.keyBoardButtonContainer.delete{
    width: 13em;
    height: 4em;
    grid-area: w;
}

.buttonPanel{
    display: grid;
    width: 120px;
    padding: 10px;
    gap: .5em;
    grid-template-areas:
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . . . . . . . . . ."
    ". . . u u u v v v w w w";
}

.keyBoardButton.btnFocused{
    box-shadow:inset 0px 0px 0px 2px #FF4C98;
}

.keyBoardButton{
    width: 4em;
    height: 4em;
    background-color: #111111;
    text-align: center;
    line-height: 4em;
}

.spaceImageDiv{
    width: 82px;
    height: 19px;
    margin: auto;
}

.deleteImageForSearch{
    width: 83px;
    height: 33px;
    margin: auto;
}

.keyBoardButton.special{
    width: 13em;
    height: 4em;
    grid-area: u;
}

.keyBoardButton.space{
    width: 13em;
    height: 4em;
    grid-area: v;
}

.keyBoardButton.delete{
    width: 13em;
    height: 4em;
    grid-area: w;
}

.keyBoardButton.gap{
    margin-right: 1em;
}