.series-screen{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
}
.series-title-items-container{

}
.series-bbc-logo-container{
    width: 213px;
    height: 36px;
}
.series-info-container{
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: 20px;
}
.series-text-container{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.series-title{
    max-height: 120px;
    font-size: 56px;
    margin: 0;
}
.series-description{
    width: 618px;
    height: 56px;
    font-size: 24px;

}
.series-episode-detail{
    font-size: 28px;
    height: 32px;
}

.series-spec-logo-container{
    width: 96px;
    height: 72px;
    margin-left: 0px;
    margin-top: 30px;
}

.series-header-image{
    height: 512px;
    width: 829px;
}

.series-header-details-container{
    display: flex;
    flex-direction: row;
}

.series-header-image-container{
    margin-left: 10px;
}

.series-spec-logo{
    width: 100%;
}

.series-bbc-logo{
    width: 100%;
}

.series-title-items-container {
    margin-left: 100px;
    margin-top: 37px;
}
.series-page-vertical-list-container{
    margin-left: 100px;
    margin-top: 50px;
}

.shrunkLeftMargin {
    margin-left: 280px;
}

.removeSeriesMargin{
    margin-left: 103px;
    z-index: 99;
}
  
.addSeriesMargin{
    margin-left: 320px;
    z-index: 99;
}