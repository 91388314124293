.avContainer {
    position: absolute;
    margin-top: 88px;
}

.avTitle{
    font-family: ReithSans-Bold;
    font-size: 44px;
    text-align: left;
    font-weight: bold;
}

.labelBold {
    font-family: ReithSans-Bold;
    font-size: 28px;
}

.settingsRow{
    width: 980px;
    display: flex;
    flex-direction: row;
    
    margin-bottom: 10px;
}
.settingsRowlanguage {
    display: grid;
    grid-template-areas: 'a . . . ''a . . . ''a . . . ''a . . . ''a . . . ''a . . . ''a . . . ''a . . . ''a . . . ';
    grid-template-columns: 230px 237px 237px 237px;  
}

.settingsPanel {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: auto;
    max-height: 750px;
}

.settingsDiv {
    line-height: 60px;
    width: 220px;
    height: 60px;
    font-family: ReithSans-Regular;
    font-size: 24px;
    margin-top: 25px;
    margin-left: 10px;
}

.settingsDiv.focused{
    border-style: solid;
    border-color:#FF4C98;
    transition: border 300ms ease-in-out;
}

.settingsDiv.unselectedFocus {
    background-color: rgb(25, 25, 26);
}

.settingsDiv.subtitles {
    height: 32px;
    grid-area: a;
    background-color: black;
    text-align: left;
}

.settingsDiv.language {
    height: 32px;
    grid-area: a;
    background-color: black;
    text-align: left;
}

.settingsDiv.playbackQuality {
    height: 32px;
    grid-area: c;
    background-color: black;
    text-align: left;
}

.settingsDiv.autoplay {
    height: 32px;
    grid-area: d;
    background-color: black;
    text-align: left;
}

.settingsDiv.longtext {
    font-family: ReithSans-Regular;
    font-size: 20px;
    width: 750px;
    height: 24px;
    grid-area: e;
    background-color: black;
    text-align: left;
}

.settingsDiv.audio {
    height: 32px;
    grid-area: a;
    background-color: black;
    text-align: left;
}

.settingsDiv.border {
    border-style:solid;
    border-width: 4px;
}

.selected {
    background-color: white;
    color: black;
}

.divLine {
    width: 1254px;
    height: 1px;
    border-bottom: 0.5px solid white;
    position: absolute;
    margin-top: 30px;
}